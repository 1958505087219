import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
// Added by the CLI
import './registerServiceWorker';
import { IonicVue } from '@ionic/vue';
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
//import VueGtag from "vue-gtag";
//import { createGtm } from '@gtm-support/vue-gtm';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import store from './store';
import EventTracker from './plugins/eventTracker.js';



const app = createApp(App)
  .use(store)
  .use(EventTracker)
  .use(router)
  .use(IonicVue)

  .use(OneSignalVuePlugin, {
    appId: '5ef434e9-891b-4596-a5a5-e0cef9c22c95',
  })
  /*
  .use(
    createGtm({
      id: "GTM-5SR5NLVZ",
      vueRouter: router
    })
  )
 
.use(VueGtag, {
    config: { id: "GTM-5SR5NLVZ" }
  })
    */
;

router.isReady().then(() => {
  store.dispatch('loadTranslations');
  app.mount('#app');
});

async function checkAppVersion() {
  try {
    // Récupérer la version depuis le fichier JSON
    const response = await fetch("/version.json");
    const { version } = await response.json();

    // Vérifier la version stockée dans localStorage
    const storedVersion = localStorage.getItem("app_version");

    if (storedVersion) {
      // Comparer avec la version actuelle
      if (storedVersion !== version) {
        console.log("Nouvelle version détectée :", version);
        // Déclenche une action (exemple : recharger la page)
        handleUpdate(version);
      } else {
        console.log("L'application est à jour :", version);
      }
    } else {
      // Stocker la version actuelle si elle n'existe pas
      localStorage.setItem("app_version", version);
      console.log("Première exécution, version enregistrée :", version);
    }
  } catch (error) {
    console.error("Erreur lors de la vérification de la version :", error);
  }
}

function handleUpdate(newVersion) {
  // Exemple : afficher une notification
  const updateBanner = document.createElement("div");
  updateBanner.innerText = `Nouvelle version disponible (${newVersion}). Cliquez pour recharger.`;
  updateBanner.style.position = "fixed";
  updateBanner.style.bottom = "0";
  updateBanner.style.width = "100%";
  updateBanner.style.backgroundColor = "#ffcc00";
  updateBanner.style.color = "#000";
  updateBanner.style.textAlign = "center";
  updateBanner.style.padding = "10px";
  updateBanner.style.cursor = "pointer";

  updateBanner.onclick = () => {
    // Mettre à jour localStorage avant de recharger
    localStorage.setItem("app_version", newVersion);
    window.location.reload();
  };

  document.body.appendChild(updateBanner);
}

// Appeler la fonction au démarrage
//checkAppVersion();